import { ParadiseDevicesTable } from "src/components/Paradise/ParadiseDevicesTable"
import {
  ParadiseLayout,
  ParadiseTitle,
} from "src/components/Paradise/sharedStyles"
import { useParadiseDevicesFilter } from "src/components/Paradise/useParadiseDevicesFilter"
import {
  useFetchParadiseDashboardDevices,
  useFetchParadiseDevicesCount,
} from "src/data/paradise/paradiseDevices/queries/paradiseDeviceQueries"
import { IParadiseDeviceListFilter } from "src/data/paradise/paradiseDevices/types/paradiseDeviceQueryTypes"
import { useSortParam } from "src/ui/GridTable/useTableColumns/useSortParam"
import { Titlebar } from "src/ui/Layout/Titlebar"
import { MBadge } from "src/ui/MBadge/MBadge"
import { spacing } from "src/ui/spacing"

const LIMIT = 50

export function ParadiseDevices() {
  const { filter, setFilter, offset, setOffset, sortedFilter } =
    useParadiseDevicesFilter()

  const { sortValue, setSortValue } = useSortParam()

  // If the user has filtered we want to show inactive devices aswell, otherwise just active devices is fetched
  const hasFiltered = Object.values(filter).some((f) => !!f)

  const fetchedDevices = useFetchParadiseDashboardDevices({
    filter: {
      limit: LIMIT,
      offset,
      device_id: filter.devices_id || undefined,
      description: filter.devices_description || undefined,
      device_mac: filter.devices_mac || undefined,
      active: !hasFiltered || undefined,
      installed_firmware: filter.devices_firmware || undefined,
      hardware_version: filter.devices_hardwareVersion ?? undefined,
      or_tags: filter.devices_tag ?? undefined,
      owner_id: filter.devices_owner || undefined,
      offline: filter.devices_offline ?? undefined,
      sort:
        (sortValue?.id as IParadiseDeviceListFilter["sort"]) || "device_mac",
      sort_by: sortValue?.order || "asc",
    },
    options: {
      keepPreviousData: true,
    },
  })

  const fetchDevicesCount = useFetchParadiseDevicesCount()

  return (
    <ParadiseLayout>
      <Titlebar
        bottomMargin={spacing.S}
        title={
          <ParadiseTitle>
            Devices
            {fetchDevicesCount.isSuccess && (
              <>
                <MBadge color="good">
                  Online: {fetchDevicesCount.data?.online}
                </MBadge>{" "}
                <MBadge color="error">
                  Offline: {fetchDevicesCount.data?.offline}
                </MBadge>
              </>
            )}
          </ParadiseTitle>
        }
      />

      <ParadiseDevicesTable
        devices={fetchedDevices.data?.devices ?? []}
        offset={offset}
        limit={LIMIT}
        setOffset={setOffset}
        filter={filter}
        setFilter={setFilter}
        sortedFilters={sortedFilter}
        sort={sortValue}
        setSort={setSortValue}
        totalCount={fetchedDevices.data?.total_count}
        isLoading={fetchedDevices.isPreviousData}
        error={{
          hasError: fetchedDevices.isError,
          title: fetchedDevices.error?.message,
        }}
      />
    </ParadiseLayout>
  )
}
